<script setup lang="ts">
  const carousel = ref<HTMLDivElement>();

  let isDown = false;
  let startX = 0;
  let scrollLeft = 0;

  useEventListener(carousel, 'mousedown', (e) => {
    if (carousel.value) {
      isDown = true;
      carousel.value.classList.add('active');
      startX = e.pageX - carousel.value.offsetLeft;
      scrollLeft = carousel.value.scrollLeft;
    }
  });

  useEventListener(carousel, 'mouseleave', () => {
    if (carousel.value) {
      isDown = false;
      carousel.value.classList.remove('active');
    }
  });

  useEventListener(carousel, 'mouseup', () => {
    if (carousel.value) {
      isDown = false;
      carousel.value.classList.remove('active');
    }
  });

  useEventListener(carousel, 'mousemove', (e) => {
    if (carousel.value) {
      if (!isDown) { return; }
      e.preventDefault();
      const x = e.pageX - carousel.value.offsetLeft;
      const walk = (x - startX) * 3; // scroll-fast
      carousel.value.scrollLeft = scrollLeft - walk;
    }
  });
</script>

<template>
  <div ref="carousel" class="carousel-container">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.carousel-container {
  width: 100%;
  overflow: scroll;
  user-select: none;

  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  &::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }
}
</style>
